import classNames from 'classnames';
import { memo, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';

import type { InboxMessage } from '@/types/messages';

import Button from '@/components/Button';
import Loader from '@/components/Loader/Loader';
import MetaValue from '@/components/MetaValue/MetaValue';
import { getMessage } from '@/services/api/inbox';

import styles from './SingleMessage.module.scss';

import ArrowLeftIcon from '@/images/icons/arrow-left.svg?react';
import ClockIcon from '@/images/icons/clock.svg?react';
import UserIcon from '@/images/icons/user-simple.svg?react';

const MessagePreview = memo(() => {
  const { name = '', messageId } = useParams();
  const navigate = useNavigate();
  const [message, setMessage] = useState<InboxMessage>();
  const isInitialized = useRef(false); // Solution for React v18 issue, when it's remounting component in StrictMode
  const [isPending, setIsPending] = useState(true);
  const [showRawMessage, setShowRawMessage] = useState(false);

  useEffect(() => {
    if (name && !isInitialized.current) {
      isInitialized.current = true;
      getMessage(name, messageId)
        .then(async (response) => {
          if (!response.ok) {
            throw new Error(`${response.statusText} (${response.status})`);
          }

          setMessage(await response.json());
          setIsPending(false);
        })
        .catch(() => {
          setIsPending(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const transformedHTML = message?.html?.replace(/target="_blank"/g, '');

  const handleBackButtonClick = () => navigate(`/${name}/`);

  const handleSwitchContentType = () => setShowRawMessage((prev) => !prev);

  return (
    <>
      <Helmet titleTemplate="Bugbug Inbox / %s">
        <title>{name}</title>
        <meta name="robots" content="noindex" />
        <meta name="googlebot" content="noindex" />
      </Helmet>
      <div className={styles.container}>
        {!isPending && message && (
          <>
            <div className={styles.messageHeader}>
              <button type="button" className={styles.backButton} onClick={handleBackButtonClick}>
                <ArrowLeftIcon />
              </button>
              <div className={styles.generalData}>
                <div className={styles.title}>{message?.subject}</div>
                <div className={styles.meta}>
                  <MetaValue
                    Icon={UserIcon}
                    value={`${message?.from[0].name} (${message?.from[0].address})`}
                  />
                  <MetaValue Icon={ClockIcon} value={message?.received} />
                  <div className={styles.actions}>
                    <Button onClick={handleSwitchContentType}>
                      {showRawMessage ? 'Show preview' : 'Show raw message'}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            {showRawMessage ? (
              <div className={classNames(styles.messageContent, styles['messageContent--raw'])}>
                {message.source}
              </div>
            ) : (
              <div
                className={styles.messageContent}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: transformedHTML ?? '' }}
              />
            )}
          </>
        )}
        {!isPending && !message && (
          <div className={styles.messageNotFound}>
            <span>Message does not exists</span>
            <Button onClick={handleBackButtonClick} variant="primary" bordered>
              Go to inbox
            </Button>
          </div>
        )}
        {isPending && (
          <div className={styles.loaderContainer}>
            <Loader />
            Loading message
          </div>
        )}
      </div>
    </>
  );
});

MessagePreview.displayName = 'MessagePreview';

export default MessagePreview;
