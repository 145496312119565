/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import { is } from 'ramda';
import { useCallback, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';

import type { ChangeEvent, FocusEvent, MouseEvent } from 'react';

import styles from './Input.module.scss';

interface InputProps {
  type?: string;
  min?: string;
  className?: string;
  autoComplete?: string;
  name?: string;
  value?: string | number;
  readOnly?: boolean;
  disabled?: boolean;
  required?: boolean;
  autoFocus?: boolean;
  fullWidth?: boolean;
  startAdornment?: string;
  endAdornment?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
  error?: boolean | string;
}

const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const {
    className,
    autoFocus = false,
    value = '',
    error,
    readOnly = false,
    disabled = false,
    startAdornment,
    endAdornment,
    fullWidth = false,
    ...inputProps
  } = props;
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = value?.toString();
    }
  }, [value]);

  useEffect(() => {
    if (autoFocus) {
      inputRef.current?.focus();
      inputRef.current?.select();
    }
  }, [autoFocus]);

  useImperativeHandle(ref, () => inputRef.current as HTMLInputElement, []);

  const handleClick = useCallback((event: MouseEvent) => event.stopPropagation(), []);
  const invalid = !!error && !readOnly;

  const wrapperClassName = classNames(className, styles.inputWrapper, {
    [styles.fullWidth]: fullWidth,
    [styles.invalid]: invalid,
  });

  return (
    <div>
      <div
        className={wrapperClassName}
        data-disabled={disabled}
        data-readonly={readOnly}
        aria-invalid={invalid}
        aria-required={inputProps.required}
      >
        {startAdornment && <span className={styles.startAdornment}>{startAdornment}</span>}
        <input
          {...inputProps}
          className={styles.inputBase}
          disabled={disabled}
          ref={inputRef}
          onClick={handleClick}
          readOnly={readOnly}
          aria-invalid={invalid}
          aria-required={inputProps.required}
        />
        {endAdornment && <span className={styles.endAdornment}>{endAdornment}</span>}
      </div>
      {is(String, error) && !readOnly && <div className={styles.errorMessage}>{error}</div>}
    </div>
  );
});

Input.displayName = 'Input';

export default Input;
