import envs from './envs';

const { production, localhost } = envs;

const getConfig = () => {
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.log('Using localhost config', localhost);
    return localhost;
  }
  return production;
};

export default getConfig();
