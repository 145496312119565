import styles from './MetaValue.module.scss';

interface IMetaValueProps {
  Icon: React.FC;
  value?: string | number;
}

const MetaValue = ({ Icon, value = '' }: IMetaValueProps) => (
  <div className={styles.container}>
    <Icon />
    <span>{value}</span>
  </div>
);

export default MetaValue;
