import * as Sentry from '@sentry/react';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { io } from 'socket.io-client';

import type { Socket } from 'socket.io-client';

import config from '@/constants/config';

const RETRY_LIMIT = 999;
const RETRY_DELAY = 1000;
const ACK_TIMEOUT = 5000;

export default function useInboxStream(onMessages = Function.prototype) {
  const { name = '' } = useParams();
  const socket = useRef<Socket>();
  const latestError = useRef<Error>();
  const [isLoading, setIsLoading] = useState(true);
  const [hasUnexpectedServerError, setHasUnexpectedServerError] = useState(false);
  const caseInsensitiveName = name?.toLowerCase();

  useEffect(() => {
    if (!caseInsensitiveName || socket.current) {
      return undefined;
    }

    socket.current = io(config.API_WS_URL, {
      path: '/ws/',
      transports: ['websocket'],
      reconnection: true,
      reconnectionDelayMax: RETRY_DELAY,
      reconnectionAttempts: RETRY_LIMIT,
      ackTimeout: ACK_TIMEOUT,
      query: {
        inboxName: caseInsensitiveName,
      },
    });

    // Connect events
    socket.current.on('connect', () => {
      setIsLoading(false);
    });

    socket.current.on('message', (messages) => {
      onMessages(messages);
    });

    // Reconnect events
    socket.current.io.on('reconnect', () => {
      latestError.current = undefined;
      console.debug('Reconnected!');
    });

    socket.current.io.on('reconnect_attempt', (attempt) => {
      console.debug('Trying to reconnect:', attempt);
    });

    socket.current.io.on('reconnect_error', (error) => {
      latestError.current = error;
    });

    socket.current.io.on('reconnect_failed', () => {
      setHasUnexpectedServerError(true);
      setIsLoading(false);
      console.error('Reconnect failed!', latestError.current);
      Sentry.captureException(latestError.current);
    });

    return () => {
      if (socket.current) {
        socket.current.close();
        socket.current = undefined;
      }
      latestError.current = undefined;
    };
  }, [caseInsensitiveName, onMessages]);

  return { name: caseInsensitiveName, isLoading, hasUnexpectedServerError };
}
