import classNames from 'classnames';
import { forwardRef, useRef, useImperativeHandle } from 'react';

import type { MouseEvent, ButtonHTMLAttributes } from 'react';

import styles from './Button.module.scss';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  variant?: 'default' | 'primary' | 'secondary' | 'inline';
  onClick?: (event?: MouseEvent<HTMLButtonElement>) => void;
  'data-testid'?: string;
  Icon?: React.FC;
  bordered?: boolean;
  disabled?: boolean;
  noContentWrapper?: boolean;
  iconPosition?: 'right' | 'left';
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const {
    className,
    variant = 'default',
    children,
    'data-testid': dataTestId,
    Icon = null,
    bordered = false,
    noContentWrapper,
    iconPosition,
    ...buttonProps
  } = props;

  const buttonRef = useRef<HTMLButtonElement>(null);
  useImperativeHandle(ref, () => buttonRef.current as HTMLButtonElement, []);
  const hasIconOnTheLeft = iconPosition === 'left';

  const renderIcon = () => (
    <span
      className={classNames(styles.iconContainer, {
        [styles.iconPositionRight]: !hasIconOnTheLeft,
      })}
    >
      {Icon ? <Icon /> : null}
    </span>
  );

  return (
    <button
      type="button"
      className={classNames(className, styles.buttonContainer, {
        [styles['button--bordered']]: bordered,
        [styles['button--default']]: variant === 'default',
        [styles['button--primary']]: variant === 'primary',
        [styles['button--secondary']]: variant === 'secondary',
        [styles['button--inline']]: variant === 'inline',
        [styles['button--withIcon']]: !!Icon,
      })}
      ref={buttonRef}
      data-testid={dataTestId}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...buttonProps}
    >
      {!!Icon && hasIconOnTheLeft && renderIcon()}
      {noContentWrapper ? children : <div>{children}</div>}
      {!!Icon && !hasIconOnTheLeft && renderIcon()}
    </button>
  );
});

Button.displayName = 'Button';

export default Button;
