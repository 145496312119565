import { Outlet, useNavigate, useParams } from 'react-router-dom';

import CopyButton from '@/components/CopyButton';

import styles from './Layout.module.scss';

import Logo from '@/images/logo.svg?react';

const Layout = () => {
  const navigate = useNavigate();
  const { name } = useParams();
  const email = `${name}@${process.env.REACT_APP_INBOX_DOMAIN}`.toLowerCase();

  return (
    <div className={[styles.container].join(' ')}>
      <header className={styles.header}>
        <Logo className={styles.logo} onClick={() => navigate('/')} />
        <div className={styles.inboxName}>
          <span>{email}</span>
          <CopyButton value={email} small />
        </div>
      </header>
      <main className={styles.content}>
        <Outlet />
      </main>
    </div>
  );
};

export default Layout;
