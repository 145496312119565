import classNames from 'classnames';
import { useImperativeHandle, useRef, forwardRef } from 'react';

import type { ReactNode, MouseEvent } from 'react';

import styles from './IconButton.module.scss';

interface IconButtonProps {
  className?: string;
  disabled: boolean;
  children: ReactNode;
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
}

const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ children = null, ...buttonProps }, ref) => {
    const buttonRef = useRef<HTMLButtonElement>(null);

    useImperativeHandle(ref, () => buttonRef.current as HTMLButtonElement, []);

    return (
      <button
        type="button"
        ref={buttonRef}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...buttonProps}
        className={classNames(buttonProps.className, styles.container)}
      >
        {children}
      </button>
    );
  },
);

IconButton.displayName = 'IconButton';

export default IconButton;
